function updateBoardeeActions(action) {
  updateFirstAction(action);

  updateTimelineSection(action);

  action.classList.add('fade-out');
  setTimeout(() => {
    action.remove();

    updateDoneActions(action);

    const availableActionsCounter = document.getElementById('available-actions-counter');
    updateCounter(availableActionsCounter, -1);

    const doneActionsCounter = document.getElementById('done-actions-counter');
    updateCounter(doneActionsCounter, 1);

    updateActionsProgress(doneActionsCounter, availableActionsCounter);


  }, 800);
}

function updateFirstAction(action) {
  if (action.classList.contains('first-action')) {
    const nextAction = action.nextElementSibling;
    if (nextAction) {
      setTimeout(() => {
        nextAction.classList.add('first-action');
        nextAction.querySelector('.action-handler .first-action-state').classList.remove('d-none');
        nextAction.querySelector('.action-handler .regular-action-state').classList.add('d-none');
      }, 800);
    }
  }
}

function updateTimelineSection(action) {
  const timelineSection = action.closest('.timeline-section');
  if (timelineSection && timelineSection.querySelectorAll('.user-action').length === 1) {
    timelineSection.classList.add("fade-out");
    setTimeout(() => {
      if (action.classList.contains('first-action')) {
        const nextTimelineSection = timelineSection.nextElementSibling;
        if (nextTimelineSection) {
          const nextAction = nextTimelineSection.querySelector('.user-action');
          if (nextAction) {
            nextAction.classList.add('first-action');
            nextAction.querySelector('.action-handler .first-action-state').classList.remove('d-none');
            nextAction.querySelector('.action-handler .regular-action-state').classList.add('d-none');
          }
        } else {
          const availableSection = document.getElementById('available');
          const emptyStateContent = document.querySelector('#empty-state-content').innerHTML;
          availableSection.innerHTML = emptyStateContent;
        }
      }
      timelineSection.remove();
    }, 800);
  }
}

function updateDoneActions(action) {
  const url = action.getAttribute('data-url-to-refresh-done-actions');
  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method: 'GET'
  })
  .then(response => response.json())
  .then(data => {
    document.getElementById('done').innerHTML = data.timeline_html;
  }).catch(error => {
    console.log(error);
  })
}

function updateCounter(counterElement, increment) {
  if (counterElement) {
    const count = parseInt(counterElement.dataset.count) + increment;
    counterElement.dataset.count = count;
    counterElement.innerText = count > 10 ? '10+' : count.toString();
  }
}

function updateActionsProgress(doneActionsCounter, availableActionsCounter) {
  if (doneActionsCounter && availableActionsCounter) {
    const futureActionsCounter = document.getElementById('future-actions-counter');
    const doneActionsCount = parseInt(doneActionsCounter.dataset.count);
    const availableActionsCount = parseInt(availableActionsCounter.dataset.count);
    const futureActionsCount = parseInt(futureActionsCounter.dataset.count);
    const progress = Math.round(doneActionsCount / (doneActionsCount + availableActionsCount + futureActionsCount) * 100);
    document.getElementById('onboarding-progress').innerHTML = `
      <label>
        <progress value="${progress}" max="100" style="--color: #46ce92;" id="onboarding-score">${progress}</progress>
        ${progress} %
      </label>
    `;
  }
}

export { updateBoardeeActions };
